p {
    margin: 0 !important;
    padding: 0;
}

.pad-mar {
    margin: 0% !important;
    padding: 0% !important;
}

@font-face {
    font-family: 'FontMedium';
    src: url('./assets/fonts/Jiho-Medium.ttf');
}

@font-face {
    font-family: 'FontMediumItalic';
    src: url('./assets/fonts/Jiho-MediumItalic.ttf');
}

@font-face {
    font-family: 'FontRegular';
    src: url('./assets/fonts/Jiho-Regular.ttf');
}

@font-face {
    font-family: 'FontItalic';
    src: url('./assets/fonts/Jiho-Italic.ttf');
}

@font-face {
    font-family: 'FontBold';
    src: url('./assets/fonts/Jiho-Bold.ttf');
}

.fontMedium {
    font-family: 'FontMedium';
}

.fontMediumItalic {
    font-family: 'FontMediumItalic';
}

.fontItalic {
    font-family: 'FontItalic';
}

.fontRegular {
    font-family: 'FontRegular';
}

.fontBold {
    font-family: 'FontBold';
}

.transparentButton {
    background-color: transparent;
    border: 0px;
}

.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.fixed {
    position: fixed;
}

.fixedContent {
    height: 100%;
    top: 0;
    bottom: 0;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
}

.gradientBlue {
    background-image: linear-gradient(to right, #0a72bb, #18abdf, #0a72bb)
}

.line {
    border-bottom: 1px #b8b8d2 solid;
    width: 100%;
}

.line30 {
    border-bottom: 1px #b8b8d2 solid;
    width: 30%;
}

.modal-height {
    height: 100% !important;
}

html {
    height: 100%;
}

html body {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

html body .container-fluids.body-content {
    height: 100% !important;
    overflow-y: auto;
    flex: 1 !important;
    flex-grow: 1 !important;
    flex-wrap: 1 !important;
}

.container-container {
    height: 75vh !important;
}

.container-container-without-bottom-tabs {
    height: 90vh !important;
}

.borderWhite {
    border: thin solid white;
    border-radius: 12px;
}

.lineShadow {
}

.unitItemContainer{
    border: 1px solid #fff;
}

.tintColorWhite{
    filter: sepia(100%) saturate(100%) brightness(10000%) hue-rotate(180deg);
}

.threeLineText{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
            line-clamp: 3; 
    -webkit-box-orient: vertical;
}